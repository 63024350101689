import styled, { css } from 'styled-components';

import { ErrorNotificationWrapper } from 'src/components/ErrorNotification/error-notification-styled';
import { H2, HHero, Text1Bold } from 'src/components/Text';
import { themeColor, smMedia, mdMedia, themeImages } from 'src/theme/utils';

export const StepWeightCuttingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${HHero} {
    display: none;
    max-width: 648px;
  }
  ${H2} {
    display: flex;
    max-width: 648px;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ${Text1Bold} {
    text-align: left;
    margin-bottom: 8px;
  }
  ${ErrorNotificationWrapper} {
    margin: 16px 0;
  }
  ${smMedia(css`
    ${H2} {
      display: block;
      text-align: center;
    }
    ${Text1Bold} {
      text-align: center;
    }
  `)}

  ${mdMedia(css`
    ${HHero} {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 40px auto 8px;
    }
    ${H2} {
      display: none;
    }
    ${Text1Bold} {
      text-align: center;
      margin-bottom: 16px;
    }
  `)}
`;

export const StepWeightCuttingChoices = styled.div`
  display: grid;

  grid-template:
    'item1 item2'
    'item3 item4'
    'item5 .';
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  justify-content: space-between;
  margin-top: 12px;
  row-gap: 16px;
  column-gap: 16px;
  ${smMedia(css`
    grid-template:
      'item1 item2 item3 . '
      'item4 item5 . . ';
    grid-template-columns: calc(33% - 8px) calc(33% - 16px) calc(33% - 8px);
    justify-content: space-around;
  `)}

  ${mdMedia(css`
    grid-template: 'item1 item2 item3 item4 item5';
    margin-top: 32px;
    row-gap: 0px;
    justify-content: space-between;
  `)}
`;

export const StepWeightCuttingChoiceItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 9px;
  width: 100%;
  height: 209px;
  background-color: ${themeColor('secondary5')};
  &:nth-child(1) {
    grid-area: item1;
  }
  &:nth-child(2) {
    grid-area: item2;
  }
  &:nth-child(3) {
    grid-area: item3;
  }
  &:nth-child(4) {
    grid-area: item4;
  }
  &:nth-child(5) {
    grid-area: item5;
  }
  ${mdMedia(css`
    background: none;
    border: 3px solid ${themeColor('blue5')};
    width: 200px;
    height: 310px;
    border-radius: 9px;
    margin-bottom: 0;
  `)}
`;

export const BellytypeImg = styled.div`
  display: flex;
  max-height: 133px;
  height: 100%;
  cursor: pointer;
  justify-content: center;
  img {
    height: 210px;
    margin-top: -20px;
    ${mdMedia(css`
      height: 316px;
    `)}
  }
  ${mdMedia(css`
    max-height: 250px;
  `)}
`;

export const BellytypeBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border: none;
  outline: none;
  height: fit-content;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  align-items: center;
  background-color: ${themeColor('blue5')};
  border-radius: 7px;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 12px;
  height: 76px;
  &[disabled] {
    cursor: not-allowed;
  }
  ${Text1Bold} {
    margin: 0;
  }
  ${mdMedia(css`
    height: 56px;
    padding: 16px;
    align-items: baseline;
  `)}
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin: 1.5rem auto 0;
  max-width: 556px;
  ${smMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 2.5rem 0 0;
  `)}
`;

export const PrivacyCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;

export const EmailConfirmWrapper = styled.div`
  display: flex;
  margin: 1rem auto 0;
  max-width: 556px;
  ${smMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 1rem 0 0;
  `)}
`;

export const EmailConfirmCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;
